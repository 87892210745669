<template>
	<div class="flex-row flex-wrap flex-justify-space-betwee">
		<div class="item boderra8 bg-white marb20 cure-pointer" v-for="(item, i) in lists" :key="i" @click="toDetail(item.id)">
			<div class="">
				<!-- <img class="item-pic display-block" :src="item.image" alt="" /> -->
				<el-image class="item-pic display-block"  :src="item.image" lazy  fit="cover"></el-image>
			</div>
			<div class="paddlr16 boderb1-00000008">
				<div class="paddt20 flex-wrap" v-if="item.tag">
					<div class="label center fz16 co-010085">{{item.tag}}</div>
				</div>
				<div class="mart20 fz18 co-333333 two-point font-blod content-descm">{{item.title}}</div>
				<div class="w100 paddb18"></div>
			</div>
			<div class="prices justify-content-center-between" v-if="item.view||item.price">
				<div class="align-items-end co-010085 font-blod">
					<span class="fz14 l14 display-block">￥</span>
					<span class="fz30 l24 display-block">{{item.price}}</span>
				</div>
				<div class="center" v-if="item.view">
					<img class="see display-block" src="@/assets/img/de_see.png" alt="" />
					<span class="fz16 co-999999">{{item.view}}</span>
				</div>
			</div>
			<div class="paddlr16 marb10" v-if="item.avatar || item.nickname">
				<div class="align-items-center ">
					
					<img class="icon_02 "  :src="item.avatar" alt="" />
					<text class="fz16 co-222222 font-blod marl10">{{item.nickname || ''}}</text>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			ptype: {
				type: [Number, String],
				default: 1
			},
			list: {
				type: Array,
				default () {
					return []
				}
			}
		},
		data() {
			return {
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				lists: ''
			}
		},
		watch: {
			list(newval) {
				this.lists = newval

			},
		},
		created() {
			this.lists = this.list
		
		},
		methods: {
			// 跳转详情
			toDetail(id) {
				this.$router.push({
					path:'/Detail',
					query: { type: 1,
							id:id
					}
				})
			}
		}
	}
</script>

<style scoped="scoped"  lang="less">
	.item {
		display: flex;
		flex-direction: column;
		width: 280px;
		margin-right: 20px;
		height: 100%;
		box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);

		.item-pic {
			width: 280px;
			height: 220px;
			border-radius: 8px 8px 0px 0px;
		}

		.label {
			padding: 0 7px;
			height: 28px;
			border: 1px solid #010085;
			border-radius: 4px;
		}
		.prices {
			height: 73px;
			padding-left: 16px;
			padding-right: 19px;
		}

		.icon_02 {
			width: 20px;
			height: 20px;
			border-radius: 50%;
		}
	}



	.see {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}
</style>
