<template>
    <div class="data">
        <el-dialog :lock-scroll='false' custom-class="boderra8" v-model="centerDialogVisible" width="400px" top="0"
            center>
            <div class="fz20 co-333333 content center">{{title}}</div>
            <div class="btn-area-01 h44 justify-content-center-between" style="border-top: 1px solid #E6E6E6;">
                <div class="flex-1 h44 center fz14 co-333333 cure-pointer" @click="close">取消</div>
                <div class="popup-middle"></div>
                <div class="flex-1 h44 center fz14 co-333333 cure-pointer" @click="sureBtn">{{yesText}}</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: '提示'
            },
            yesText: {
                type: String,
                default: '确定'
            }
        },
        data() {
            return {
                centerDialogVisible: false, // 是否打开弹窗
            }
        },
        methods: {
            // 打开弹窗
            open() {
                this.$nextTick(() => {
                    this.centerDialogVisible = true
                });
            },
            // 确认操作
            sureBtn() {
                this.$emit('sureBtn', 1);
            },
            // 取消操作
            close() {
                this.$nextTick(() => {
                    this.centerDialogVisible = false
                });
            }
        }
    }
</script>

<style lang="less">
    .el-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .btn-area-01 {
        width: 100%;
        height: 55px;
        opacity: 1;

        .popup-middle {
            width: 0px;
            height: 42px;
            border: 1px solid #E6E6E6;
        }
    }

    .data .el-dialog__header {
        padding: 0;
    }

    .data .el-dialog--center .el-dialog__body {
        padding: 0 10px;
    }
</style>
