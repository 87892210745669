<template>
	<div class="display-flex flex-wrap">
		<div class="item boderra8 bg-white marb20 cure-pointer paddb23" style="overflow: hidden;" v-for="(item, i) in lists" :key="i" @click="toDetail(item.id)">
			<div class="" >
				<!-- <img class="item-pic display-block" :src="item.image" alt="" /> -->
				<el-image class="item-pic display-block"  :src="item.image" lazy fit="cover"></el-image>
			</div>
			<div class="paddlr16 paddb23">
				<div class="paddt20 flex-wrap" v-if="item.tag">
					<div class="label bg-F7F7F7 center fz16 co-999999">{{item.tag}}</div>
				</div>
				<div class="mart20 fz20 co-333333 l20 two-point font-blod " style='line-height: 26px;'  v-if="item.title">{{item.title}}</div>
			</div>
			<!-- <div class="paddlr16 marb10" v-if="item.avatar || item.nickname">
				<div class="align-items-center ">
					
					<img class="icon_02 "  :src="item.avatar" alt="" />
					<text class="fz16 co-222222 font-blod marl10">{{item.nickname || ''}}</text>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			// 1素材市场 2研图社 3竞赛信息 4院校指南
			ptype: {
				type: [Number, String],
				default: 1
			},
			list: {
				type: Array,
				default () {
					return []
				}
			}
		},
		data() {
			return {
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				lists: [], // 列表
			}
		},
		watch: {
			list(newval) {
				this.lists = newval
			
			},
		},
		created() {
			this.lists = this.list
		
		},
		methods: {
			// 跳转详情
			toDetail(id) {
				this.$router.push({  //核心语句
					path:'/ClassDetail',   //跳转的路径,
					query: {
						id: id
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.item {
		width: 285px;
		margin-right: 20px;
		box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
		height: 100%;
		.item-pic {
			width: 285px;
			height: 220px;
			border-radius: 8px 8px 0px 0px;
		}
		
		.label {
			padding: 0 7px;
			height: 28px;
			border-radius: 4px;
		
		}
		.icon_02 {
			width: 20px;
			height: 20px;
			border-radius: 50%;
		}
	}
	
	.item:nth-child(4n) {
		margin-right: 0;
	}
</style>
