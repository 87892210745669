<template>
	<div class="">
		<div class="list">
			<div class="list-item paddt20 paddb20 justify-content-center-between cure-pointer" v-for="(item, i) in list"
				:key="i" :class="i < 3 ? 'boderb1-E6E6E6' : ''" @click="toClass(item)">
				<div>
					<div class="fz16 co-333333 font-blod">{{item.title}}</div>
					<div class="fz16 co-999999 mart10">{{item.mini_title}}</div>
					<div class="align-items-center mart18">
						<span class="fz20 co-010085 font-blod">￥{{item.price}}</span>
						<span class="display-block fz20 co-999999 marl10">/{{item.unit}}</span>
					</div>
				</div>

				<div>
					<img class="arrow_right_blank display-block" src="@/assets/img/arrow_right_blank.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			list: {
				type: Array,
				default () {
					return []
				}
			},
			type:{
				type:Number,
				 default: 1
			}
		},
		data() {
			return {
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态

			}
		},
		methods: {
			// 跳转课程
			toClass(item) {
				
					this.$router.push({
						path:'/ServiceClass',
						query: {
							type:this.type,
							id:item.id
						}
					})
				}
			}
		}
</script>

<style lang="less" scoped="scoped">
	.list {
		padding-left: 20px;
		padding-right: 16px;

		.arrow_right_blank {
			width: 14px;
			height: 14px;
		}
	}
	
</style>
