import {
	createRouter,
	createWebHashHistory
} from "vue-router";

const routes = [{
		path: "/",
		name: "Index",
		component: () => import('@/views/Index.vue'),
		children: [{
			path: '/',
			name: 'Indexpart',
			component: () => import('@/views/Indexpart/Indexpart.vue')
		}, {
			path: '/InspirationPart',
			name: 'InspirationPart',
			component: () => import('@/views/Indexpart/InspirationPart.vue')
		}, {
			path: '/MaterialMarket',
			name: 'MaterialMarket',
			component: () => import('@/views/Indexpart/MaterialMarket.vue')
		}, {
			path: '/CompetitionInfo',
			name: 'CompetitionInfo',
			component: () => import('@/views/Indexpart/CompetitionInfo.vue')
		}, {
			path: '/YantuSociety',
			name: 'YantuSociety',
			component: () => import('@/views/Indexpart/YantuSociety.vue')
		}, {
			path: '/School',
			name: 'School',
			component: () => import('@/views/Indexpart/School.vue')
		}, {
			path: '/Detail',
			name: 'Detail',
			component: () => import('@/views/Indexpart/Detail.vue')
		}, {
			path: '/MaterialDetail',
			name: 'MaterialDetail',
			component: () => import('@/views/Indexpart/MaterialDetail.vue')
		},  {
			path: "/DownApp",
			name: "DownApp",
			component: () => import('@/views/DownApp.vue'),
		}, {
			path: "/CourseSelection",
			name: "CourseSelection",
			component: () => import('@/views/CourseSelection.vue'),
		}, {
			path: "/GoodClass",
			name: "GoodClass",
			component: () => import('@/views/GoodClass/GoodClass.vue'),
		}, {
			path: "/ClassDetail",
			name: "ClassDetail",
			component: () => import('@/views/GoodClass/ClassDetail.vue'),
		}, {
			path: "/Teacher",
			name: "Teacher",
			component: () => import('@/views/Teacher.vue'),
		}, {
			path: "/TeacherDetail",
			name: "TeacherDetail",
			component: () => import('@/views/TeacherDetail/TeacherDetail.vue'),
		}, {
			path: "/ServiceItem",
			name: "ServiceItem",
			component: () => import('@/views/TeacherDetail/ServiceItem.vue'),
		},
		{
			path: "/SchoolList",
			name: "SchoolList",
			component: () => import('@/views/TeacherDetail/SchoolList.vue'),
		}, 
		{
			path: "/ServiceClass",
			name: "ServiceClass",
			component: () => import('@/views/TeacherDetail/ServiceClass.vue'),
		}, {
			path: '/Teacher_interview',
			name: 'Teacher_interview',
			component: () => import('@/views/TeacherDetail/Teacher_interview.vue')
		}, {
			path: "/Sincerity",
			name: "Sincerity",
			component: () => import('@/views/Sincerity.vue'),
		}, {
			path: "/SincerityDetail",
			name: "SincerityDetail",
			component: () => import('@/views/SincerityDetail/SincerityDetail.vue'),
		}, {
			path: "/Introduction",
			name: "Introduction",
			component: () => import('@/views/SincerityDetail/Introduction.vue'),
		}, {
			path: '/Strategy',
			name: 'Strategy',
			component: () => import('@/views/Indexpart/Strategy.vue')
		}, {
			path: '/OrderDetail',
			name: 'OrderDetail',
			component: () => import('@/views/Minepart/OrderDetail.vue')
		}, {
			path: '/FamousTeacherDetail',
			name: 'FamousTeacherDetail',
			component: () => import('@/views/Minepart/FamousTeacherDetail.vue')
		}, {
			path: '/AssessmentDetail',
			name: 'AssessmentDetail',
			component: () => import('@/views/Minepart/AssessmentDetail.vue')
		}, {
			path: '/About',
			name: 'About',
			component: () => import('@/views/About.vue')
		}, {
			path: '/SubmitOrder',
			name: 'SubmitOrder',
			component: () => import('@/views/GoodClass/SubmitOrder.vue')
		}, {
			path: '/SearchPage',
			name: 'SearchPage',
			component: () => import('@/views/SearchPage.vue')
		},
		{
			path: '/vip_ordelist',
			name: 'vip_ordelist',
			component: () => import('@/views/Minepart/vip_ordelist.vue')
		}
		]
	},
	{
		path: "/Home",
		name: "Home",
		component: () => import('@/views/Home.vue'),
	},
	{
		path: "/Mine",
		name: "Mine",
		component: () => import('@/views/Mine.vue'),
		children: [
			{
				path: '/Myvip',
				name: 'Myvip',
				component: () => import('@/views/Minepart/Myvip.vue')
			},
			{
				path: '/Myinfo',
				name: 'Myinfo',
				component: () => import('@/views/Minepart/Myinfo.vue')
			},
			 {
			 	path: '/Myinfo',
			 	name: 'Myinfo',
			 	component: () => import('@/views/Minepart/Myinfo.vue')
			 },
			  {
			  	path: '/MyCollection',
			  	name: 'MyCollection',
			  	component: () => import('@/views/Minepart/MyCollection.vue')
			  }, 
			{
				path: '/Myintegral',
				name: 'Myintegral',
				component: () => import('@/views/Minepart/Myintegral.vue')
			}, {
				path: '/MyFollow',
				name: 'MyFollow',
				component: () => import('@/views/Minepart/MyFollow.vue')
			}, {
				path: '/OrderList',
				name: 'OrderList',
				component: () => import('@/views/Minepart/OrderList.vue')
			}, {
				path: '/Mycoupon',
				name: 'Mycoupon',
				component: () => import('@/views/Minepart/Mycoupon.vue')
			}, {
				path: '/MyClass',
				name: 'MyClass',
				component: () => import('@/views/Minepart/MyClass.vue')
			}, {
				path: '/FamousTeacher',
				name: 'FamousTeacher',
				component: () => import('@/views/Minepart/FamousTeacher.vue')
			}, {
				path: '/MyAddress',
				name: 'MyAddress',
				component: () => import('@/views/Minepart/MyAddress.vue')
			}, {
				path: '/Cooperation',
				name: 'Cooperation',
				component: () => import('@/views/Minepart/Cooperation.vue')
			}, {
				path: '/Assessment',
				name: 'Assessment',
				component: () => import('@/views/Minepart/Assessment.vue')
			}, {
				path: '/Mymateria',
				name: 'Mymateria',
				component: () => import('@/views/Minepart/Mymateria.vue')
			},
		]
	}
];

const router = createRouter({
	// mode: 'hash',
	// history: createWebHistory(process.env.BASE_URL),
	history: createWebHashHistory(),
	routes,
});

export default router;
