import {
	createApp,
} from "vue";
import App from "./App.vue";
import $ from 'jquery';
// 引入element ui
import ElementPlus from 'element-plus';
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/lib/theme-chalk/index.css';
//引入视频播放控件
// import Bus from 'vue-happy-bus';
// console.log(Bus)


import VideoPlayer from "vue-video-player/src";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
// 引入自定义组件
import loadMore from "./components/loadMore/loadMore.vue";
import Inspiration from './components/inspiration.vue';
import ClassSelect from './components/classSelect.vue';
import Material from './components/Material.vue';
import Competition from './components/Competition.vue';
import Drawing from './components/Drawing.vue';
import Service from './components/Service.vue';
import SincerityList from './components/SincerityList.vue';
import SureCancelPopup from './components/SureCancelPopup.vue';
import pageheader from "./components/pageheader/pageheader.vue";
import teacherlist from "./components/teacherlist.vue";
import likeComment from "./components/likeComment.vue";
import tgshare from "./components/tg-share.vue";

// import waterfall from "vue-waterfall2"; 
import axios from 'axios';
import router from "./router";
import store from "./store";
import webSocket from './common/webSocket.js';


router.afterEach((to, from, next) => {
	window.scrollTo(0, 0)
})
router.beforeEach((to, from, next) => {
	if (to.matched.some(res => res.meta.requireAuth)) { // 验证是否需要登陆
		var id = localStorage.getItem('user_info');
		if (id) { // 查询本地存储信息是否已经登陆
			next();
		} else {
				next();
		}
	} else {
		next();
	}
	if (window.location.href.indexOf('code') >= 0) {
		//如果url中包含code,则保存到store中  
		let code = window.location.href.split("?")[1];
		code = code.substring(5, code.indexOf('&'));
		store.state.code = code
		// store.commit('setcode',code)
	}
});
const app = createApp(App);

let base_url = 'https://app.archipocket.com'; //正式;
// let base_url = 'https://jianzhu.togy.com.cn'; //测试;
app.config.globalProperties.$httpUrl = base_url;
app.config.globalProperties.$webSocket = webSocket;
// app.config.globalProperties.$message = ElementPlus;

app.config.globalProperties.token = false;
let $req = (params) => {
	let req = () => {
		// 显示加载中
		if (params.loading) {
			store.commit('setLoading', {
				is_loading: true,
				loading_text: params.loading
			})
		}
		// 发起请求
		let data = {
			url: params.url,
			method: params.method || 'get',
			baseURL: base_url,
			headers: {
				'Content-Type': 'application/json',
				"Authorization": localStorage.getItem('token') || store.state.token,
			},
			 timeout: params.timeout || 1000 * 60,
		}
		if (params.method == 'get') {
			data.params = params.data;
		} else {
			data.data = params.data;
		}
		// // 后台返回数据的状态码
		// request_no_token_code: 4000, // 请求没有携带token的code值
		// request_token_error: 4001, // 被踢下线
		// request_token_overdue_code: 4002, // token过期的code值
		// request_bing_phone: 2001, // 需要绑定手机号,小程序中会返回session_key
		// request_token_unable_renew: 6001, // token无法续期；
		// request_password_changed: 6002, // 密码已修改，请重新登录；
		// request_user_deleted: 6003, // 用户已被删除/已注销；
		let loging = false; // 登录中
		axios(data).then(function(res) {
				params.complete && params.complete(res)
				// 隐藏加载中
				if (params.loading) {
					store.commit('setLoading', {
						id_loading: false,
						loading_text: '加载中'
					})
				}
			
				// 需要登录才能使用的接口，且用户未登录，跳转到登录页面
				if (res.data.code == '4000') {
					store.commit('clearUserInfo')
					router.push({
						name: 'Home'
					})
				} else if (res.data.code == '4001') {
					if (!localStorage.getItem('token')){
						store.commit('clearUserInfo')
						store.commit('setShowLogin',true)
						router.push({
							name: 'Home'
						})
						return
					}
					$req({
						method: 'post',
						url: "/api/refreshToken",
							
						success: res => {
							
							localStorage.setItem('token', res.token);
							localStorage.setItem('timeout', res.expire);
							
						}
					})
				} else if (res.data.code == '6001') {
					// 在其他地方登录了，需要重新登录
					// bug 181 放开提示；
					// Vue.prototype.$err('您的账号在其它地方登录了，需要重新登录。如果不是本人操作，请尽快修改密码！');
					//过期后清除token
					// let info = store.state.user_info;
					// info.token = null;
					store.commit('clearUserInfo')
					router.push({
						name: 'Home'
					})
				} else if (res.data.code == '4002') {
					
					let token = localStorage.getItem('token'); // token
					let expire = localStorage.getItem('timeout');
					if (token && expire) {
						console.log('token失效了')
					  let time = (new Date()).valueOf(); // 现在时间
					  if (time < expire && time >= expire - 1000 * 60 * 20) {
					    // token就快要过期了（小于20分钟）,主动去刷新token
						console.log('token就快要过期了,主动去刷新token')
					    loging = true;
					    $req({
					    	method: 'post',
					    	url: '/api/refreshToken',
					    	success: res => {
								console.log(res)
					    		localStorage.setItem('token', res.token);
					    		localStorage.setItem('timeout', res.expire);
					    	},
					    	fail: res => {
								console.log( res.data.message)
					    	}
					    });
					    return false;
					  }
					  if (time > expire) {
					    // token过期，自动去登录
						console.log('token过期，需要去登入登录')
						store.commit('clearUserInfo')
					    loging = true;
						
						  router.push({
							name: 'Home'
						  })
					    return false;
					  }
					}
				}
				// 暂无数据
				else if (res.data.code == 4004 || res.data.code == 5555) {
					// 5555  移动支付未支付
				} else if (res.data.code == 200 || res.data.code == 4008 || res.data.code == 4004) {
					if (params.hint) {
						// Vue.prototype.$success(params.hint)
					}
					params.success && params.success(res.data.data, res.data);
				} // 提示报错信息
				else {
					params.fail && params.fail(res);
					if (params.showToast !== false) {
						// Vue.prototype.$warn(res.data.message)
					}
				}
				let token = localStorage.getItem('token'); // token
				let expire = localStorage.getItem('timeout');
				if (token && expire) {
					let time = new Date().getTime();
					if (time < expire && time >= expire - 1000 * 60 * 20) {
						// token过期后超过7天未登录；
						console.log('token过期续约')
						$req({
							method: 'post',
							url: '/api/refreshToken',
							success: res => {
								
								localStorage.setItem('token', res.token);
								localStorage.setItem('timeout', res.expire);
							},
							fail: res => {
								this.$message({
									type: 'error',
									message: res.data.message
								});
							}
						});
					}
				}
			})
			.catch(function(error) {
			
				if (!store.state.token || !localStorage.getItem('token')) {
					store.state.ontoken = true;
					return
				}
				console.log(error)
				// 隐藏加载中
				if (params.loading) {
					store.commit('setLoading', {
						id_loading: false,
						loading_text: '加载中'
					})
				}
			});
	}
	if (params.content) {
		// Vue.prototype.$model({
		// 	content: params.content,
		// 	title: params.title, //标题
		// 	success: () => {
		// 		req()
		// 	}
		// })
	} else {
		req();
	}
}
import utilscopy from './common/utils/clipboard.js'; //文本
app.config.globalProperties.$utilscopy = utilscopy;
app.config.globalProperties.$req = $req;
app.config.globalProperties.$get = (params) => {
	params.method = 'get';
	$req(params);
};
app.config.globalProperties.$post = (params) => {
	params.method = 'post';
	$req(params);
};
app.config.globalProperties.$put = (params) => {
	params.method = 'put';
	$req(params);
};
app.config.globalProperties.$del = (params) => {
	params.method = 'delete';
	$req(params);
};
// token过期重新获取token
window.isRefreshing = false;
// 存储请求的数组
let refreshSubscribers = [];
/*将所有的请求都push到数组中*/
function subscribeTokenRefresh(cb) {
	refreshSubscribers.push(cb);
}
// 数组中的请求得到新的token之后自执行，用新的token去请求数据
function onRrefreshed(token) {
	refreshSubscribers.map(cb => cb(token));
}
// 获取用户信息
let getUserInfo = (callback) => {
	if (localStorage.getItem('token')) {
		$req({
			method: 'get',
			url: '/api/member',
			success: res => {
				store.commit('setUserInfo', res)
				callback && callback(res);
				webSocket.checkConnect(); // 检测websocket链接
			},
			fail: res => {
				this.$message({
					type: 'error',
					message: res.data.message
				});
			}
		});
	}
};

app.config.globalProperties.getUserInfo = getUserInfo;
// 登录、注册、绑定，成功后即为登录成功
app.config.globalProperties.tokenSuccess = res => {
	// store.commit('setUserInfo', res);
	getUserInfo();
	// if (res.type == 3)
	// 	router.push({
	// 		name: 'PersonalCenter/OrderManagement2'
	// 	});
	// else
	// 	router.push({
	// 		name: 'HomeIndex'
	// 	});
}




// 引入全局公共样式
import "@/assets/css/public.css";
import "@/assets/css/item.css";
//视频控件
// app.component('Videos', Videos);
// 项目灵感
app.component('Inspiration', Inspiration);
// 课程优选
app.component('ClassSelect', ClassSelect);
// 素材市场
app.component('Material', Material);
// 竞赛信息
app.component('Competition', Competition);
// 研图社
app.component('Drawing', Drawing);
// 服务项目
app.component('Service', Service);
// 诚信机构
app.component('SincerityList', SincerityList);
// 确认取消弹窗
app.component('SureCancelPopup', SureCancelPopup);
app.component('likeComment', likeComment);
app.component('tgshare', tgshare);
//顶部栏
app.component('pageheader', pageheader);
app.component('loadMore', loadMore);
app.component('teacherlist', teacherlist);
app.use(ElementPlus,{locale});
app.use(VideoPlayer);
app.component('');
// app.provide('$message', ElementPlus)
app.use(store).use(router).mount("#app");
