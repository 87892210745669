<template>
	<el-dialog v-model="dialogVisible" width='800px' title="分享链接">
		<div class="display-flex ">
			<div class="fz20">
				{{dataObj.title}}
			</div>
		</div>
		<div class="align-items-center mart20 ">
			<div class="fz20 flex" style="width: 120px;">
				链接地址：
			</div>
			<div class="justify-content-between w100 cure-pointer" @click="shareItem(shareUrl)"
				style="border-bottom: 1px solid #D9D9D9;">
				<div class="fz18 paddb5 justify-content-between" style="color: #010085;">
					{{shareUrl}}
				</div>
				<img src="@/assets/img/td_05.png" style="width: 20px;height: 20px;">
			</div>


		</div>
	</el-dialog>
</template>

<script>
	import {
		getCurrentInstance,
		reactive,
		ref,
		defineComponent
	} from 'vue';
	import {v4}  from 'uuid'
	export default {
		setup(props, ctx) {
			const req = getCurrentInstance();
			const request = req.appContext.config.globalProperties;
			return {
				request,
			};
		},
		props: {
			shareUrl: {
				type: String,
				default () {
					return ''
				}
			},
			dataObj: { //分享参数
				type: Object,
				default () {
					return {}
				}
			}
		},
		data() {
			return {

				dialogImageUrl: "",
				dialogVisible: false,
				uuid:''
			}
		},
		mounted() {
			this.uuid = v4()
		},
		methods: {
			
			shareItem(e) {
				const temp = v4()
				this.uuid = temp;
				let userInfo = JSON.parse(localStorage.getItem('user_info'))
				if(!userInfo.id){
					console.log('没有用户信息去登入')
				}
				let shareTime = ''
				shareTime = new Date().getTime()
				let url = e+'&share=1'+'&shareTime='+shareTime +'&userId='+userInfo.id
						console.log(url)
				this.request.$utilscopy(url)
				this.$message.success('复制成功')
				this.dialogVisible=false
			},
			open() {
				
				this.$nextTick(() => {
					this.dialogVisible = true
				});
			},
		}
	}
</script>

<style scoped="scoped" lang="less">

</style>
