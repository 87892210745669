import {
	createStore
} from "vuex";

export default createStore({
	state: {
		path: '/IndexPart',
		user_info: localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info')) : {},
		token: localStorage.getItem('token'),
		loading_text: '加载中', //加载文本
		code: '', //二维码code
		showLogin: false, // 是否去登入
		orderDetail:""//素材支付订单信息
	},
	mutations: {
		// 设置背景
		setBg(state, path) {
			state.path = path
		},
		// 退出登录，清除用户信息
		clearUserInfo(state) {
		    state.user_info = {};
		    localStorage.removeItem('user_info');
		    localStorage.removeItem('token');
		},
		// 设置用户信息，登录后及修改资料后更新
		setUserInfo(state, info) {
		   
		    state.user_info = info;
		    localStorage.setItem('user_info', JSON.stringify(info))
			
		},
		// setcode(state,code){
		// 	state.code = code;
		// },
		// 设置加载中
		setLoading(state, info) {
		    state.is_loading = info.is_loading;
		    state.loading_text = info.loading_text;
		},
		// 设置调整登入页面
		setShowLogin(state, status) { 
		    state.showLogin = status;
		},
	},
	actions: {
		
	},
	modules: {
		
	},
});
