<template>
	<div class="mart2">
		<div class="w1200 main bg-white paddt24">
			<div class="main-content display-flex" v-for="(item, i) in dataArr" :key="i">
				<div>
					<img class="head-pic-02 display-block" :src="item.avatar" alt="" />
				</div>
				<div class="flex-1 marl14 paddb21">
					<div class="fz20 co-333333 h30 align-items-center font-blod">{{item.nickname}}</div>
					<div class="fz20 co-333333 mart12 justify-content-center-between">
						<span>{{item.content}}</span>
						<div class="display-flex align-items-center" @click="replyLike(i)">
							<img class="comment-like display-block cure-pointer"
								:src="require(`@/assets/img/de_0${item.is_favorite ? '9' : '7'}.png`)" alt="" />
							<span class="fz16 co-999999  marl10" :class="!item.is_favorite? 'co-999999' : 'co-010085'"
								style="padding-top: 5px;">{{item.favorite}}</span>
						</div>
					</div>
					<div class="align-items-center mart14">
						<span class="fz16 co-999999 l16">{{item.create_time}}</span>
						<span class="cure-pointer fz16 co-999999 display-block marl36 l16"
							@click="replyComment(item,i)">回复</span>
							
					</div>
					<div class="display-flex mart20" v-for="(e, ii) in item.reply" :key="ii">
						
						<div>
							<img class="head-pic-02 display-block" :src="e.avatar" alt="" />
						</div>
						<div class="flex-1 marl14">
							<div class="fz20 co-333333 h30 align-items-center font-blod">{{e.nickname}}</div>
							<div class="fz20 co-333333 mart12 justify-content-center-between">
								<span>{{e.content}}</span>
								<div class="display-flex align-items-center" @click="replyLike(i, ii)">
									<img class="comment-like display-block cure-pointer"
										:src="require(`@/assets/img/de_0${e.is_favorite ? '9' : '7'}.png`)" alt="" />
									<span class="fz16 co-999999  marl10"
										:class="!e.is_favorite? 'co-999999' : 'co-010085'"
										style="padding-top: 5px;">{{e.favorite}}</span>
								</div>
							</div>
							<div class="align-items-center mart14">
								<span class="fz16 co-999999 l16">{{e.create_time}}</span>
								<span class="cure-pointer fz16 co-999999 display-block marl36 l16"
									@click="replyComment(e,i)">回复</span>
							</div>
						</div>
					</div>
					<div >
						<div class="cure-pointer fz16 co-010085 mart20" @click="checkAll(item,i)" v-if="item.reply.length>=2&&item.isCheckAll"
							>查看全部回复</div>
						<div class="cure-pointer fz16 co-010085 mart20" v-if="item.reply.length>=2&&!item.isCheckAll"
							@click="checkAll(item,i)">收起</div>
					</div>


				</div>
			</div>
		</div>
		<loadMore v-if="list.length == 0" :state="3" nothing_txt="暂无评论" />
		<loadMore v-if="list.length > 0" :state="2" />
	</div>
</template>

<script>
	export default {
		name: 'likeComment',
		props: {
			list: null, // 评论列表
		},
		data() {
			return {
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				dataArr: [], //数据接收列表
				isClick: false,
				isGetting: false,
			}
		},
		watch: {
			Say_others:{
				handler(n, o) {
					console.log(n)
				}
			},
			list: {
				handler(n, o) {
					this.dataArr = n.map((item)=>{
						item.isCheckAll = true
						return item
					})
				},
				immediate: true,
				deep: true,
			}
		},
		created() {
			this.dataArr = this.list
		},
		methods: {
			replyComment(item, i) {
				// 回复用户评论
				this.$emit('replyComment', item, i)
			},
			// 点赞评论
			like(e) {
				this.$emit('like', e)
			},
			// 点赞回复
			replyLike(fi, ci) {
				if (this.isGetting) return
				this.isGetting = true
				let id = '',
					key = '',
					key1 = ''
				if (ci >= 0) {
					id = this.dataArr[fi].reply[ci].id
					this.dataArr[fi].reply[ci].is_favorite = !this.dataArr[fi].reply[ci].is_favorite
					key = this.dataArr[fi].reply[ci].is_favorite
					if (key) {
						this.dataArr[fi].reply[ci].favorite++
					} else {
						this.dataArr[fi].reply[ci].favorite--
					}
				} else {
					id = this.dataArr[fi].id
					this.dataArr[fi].is_favorite = !this.dataArr[fi].is_favorite
					key = this.dataArr[fi].is_favorite
					if (key) {
						this.dataArr[fi].favorite++
					} else {
						this.dataArr[fi].favorite--
					}
				}
				this.$req({
					method: 'post',
					url: '/api/mapleCollection',
					data: {
						id,
						type: 'comment'
					},
					success: res => {
						if (key) {
							this.$message.success('点赞成功')
						} else {
							this.$message('取消点赞点赞')
						}
					},
					fail: error => {
						this.$message(error.data.message)
						if (!ci) {
							id = this.dataArr[fi].reply[ci].id
							this.dataArr[fi].reply[ci].is_favorite = !this.dataArr[fi].reply[ci].is_favorite
							key = this.dataArr[fi].reply[ci].is_favorite
							if (key) {
								this.dataArr[fi].reply[ci].favorite++
							} else {
								this.dataArr[fi].reply[ci].favorite--
							}
						} else {
							id = this.dataArr[fi].id
							this.dataArr[fi].is_favorite = !this.dataArr[fi].is_favorite
							key = this.dataArr[fi].is_favorite
							if (key) {
								this.dataArr[fi].favorite++
							} else {
								this.dataArr[fi].favorite--
							}
						}
					},
					complete:()=>{
						this.isGetting = false
					}
				});
			},
			checkAll(e, i) {
				// 查看全部回复
				if (this.isClick) return
				this.isClick = true
				let arr = JSON.parse(JSON.stringify(this.dataArr))
				if (arr[i].isCheckAll) {
					this.$req({
						method: 'get',
						url: '/api/comment/reply',
						data: {
							comment_id: e.id,
						},
						success: res => {
							arr[i].reply = res
							arr[i].isCheckAll = false
							this.dataArr = JSON.parse(JSON.stringify(arr))
							this.isClick = false
						},
						fail: error => {

						}
					});
				} else {
					arr[i].reply.splice(2)
					arr[i].isCheckAll = true
					this.isClick = false
					this.dataArr = JSON.parse(JSON.stringify(arr))
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.em-box {
		width: 320px;
		height: 150px;
		padding: 13px;
		overflow-y: hidden;
		background: #FFFFFF;
		box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.08);
		border-radius: 8px;
		right: -96px;
		top: 38px;

		.em-box-inside {
			border-bottom: 1px solid #E6E6E6;

			.em-item {
				width: 30px;
				height: 30px;
				border-left: 1px solid #E6E6E6;
				border-top: 1px solid #E6E6E6;
				border-right: 1px solid #E6E6E6;
			}
		}
	}

	.title-item {
		min-width: 70px;
		padding: 0 5px;
		height: 28px;
		border-radius: 3px;
	}

	.main {
		margin: 0 auto;

		.de-01 {
			width: 20px;
			height: 20px;
		}

		.de-03 {
			width: 20px;
			height: 20px;
		}

		.de-04 {
			width: 32px;
			height: 32px;
		}

		.mian-pic {
			width: 1160px;
			height: 450px;
		}

		.like-btn {
			padding: 0 28px;
			height: 50px;
			border: 1px solid #999999;
			border-radius: 30px;
		}

		.like-btn-active {
			border: 1px solid #DCFF03 !important;
		}

		.de-07 {
			width: 30px;
			height: 30px;
		}

		.main-content {
			padding-left: 20px;
			padding-right: 19px;
		}
	}

	.textarea {
		width: 100%;
		padding: 10px;
		height: 54px;
		outline: none;
		background: #F7F7F7;
		border: 0;
		resize: none;
	}

	.head-pic-01 {
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}

	.de_08 {
		width: 20px;
		height: 20px;
	}

	.head-pic {
		width: 50px;
		height: 58px;
	}

	.submit-btn {
		width: 72px;
		height: 28px;
		background: #DCFF03;
		border-radius: 3px;
	}

	.head-pic-02 {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	.comment-like {
		width: 20px;
		height: 20px;
	}
</style>
