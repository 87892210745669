<template>
	<el-dialog v-model="dialogVisible" width='750px'>
		
			<div  style="overflow:auto;height: 750px;">
				<img style="width: 100%;" :src="dialogImageUrl" alt="">
			</div>



	</el-dialog>
</template>

<script>
	export default {
		name: 'Preview',

		data() {
			return {

				dialogImageUrl: "",
				dialogVisible: false
			}
		},

		methods: {
			open() {
				this.$nextTick(() => {
					this.dialogVisible = true
				});
			},
			bigImgShow(url) {
				this.open();
				this.dialogImageUrl = url;
			}

		}
	}
</script>

<style scoped="scoped" lang="less">

</style>
