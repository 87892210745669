<template>
	<!-- 其他页面顶部导航 -->
	<div class="h80 nav_area display-flex fixed top0 left0 right0 " style="z-index: 99;">
		<div class="nav display-flex align-items-center">
			<div class="center cure-pointer">
				<img class="logo display-block" src="@/assets/img/login_logo.png" alt=""
					@click="$router.replace({ path:'/' })">
			</div>
			<div class="center title_item title_item_01 h100 cure-pointer relative" v-for="(item, i) in titleList"
				:key="i" @mousemove="navMove(i)" @click="navChoose(item.link)" @mouseleave="navLeave">
				<span class="fz16 l16"  	
					:class="$store.state.path == item.link ? 'co-010085' : 'co-333333'">{{item.name}} 
					</span>
				<img class="i_01 marl8 display-block" :class="navIndex == 0 ? 'rotate' : ''"
					src="@/assets/img/in_01.png" alt="" v-if="i == 0">
				<img class="i_01 marl8 display-block" :class="navIndex == 1 ? 'rotate' : ''"
					src="@/assets/img/in_01.png" alt="" v-if="i == 1">
				<div class="absolute left0 right0 select select_01 bg-white"
					:style="`height: ${navIndex == 0 ? '200px' : 0}`" v-if="i == 0">
					<div class="fz14 select-item center" v-for="(selectItem, ii) in selectList"
						:class="$store.state.path == selectItem.link ? 'co-010085' : 'co-333333'" :key="ii"
						@click.stop="childrenClick(selectItem.link)">{{selectItem.name}}</div>
				</div>
				<div class="absolute left0 right0 select select_01 bg-white"
					:style="`height: ${navIndex == 1 ? '80px' : 0}`" v-if="i == 1">
					<div class="fz14 select-item center" v-for="(selectItem, ii) in selectListTwo"
						:class="$store.state.path == selectItem.link ? 'co-010085' : 'co-333333'" :key="ii"
						@click.stop="childrenClick(selectItem.link)">{{selectItem.name}}</div>
				</div>
			</div>
			<div class="h100 align-items-center">
				<div class="search-box justify-content-center-between cure-pointer" @click="$router.push({ path: '/SearchPage' })">
					<div  class="fz16 co-999999 flex-1 search-input ">输入搜索内容</div>
					<img class="search_icon display-block marl12 marr7" src="@/assets/img/search_icon.png" alt="" />
				</div>
				<div class="h100 align-items-center marl37 cure-pointer relative z100" v-if="JSON.stringify(user_info)!='{}'"
					@mouseenter="isshownotice = true" @mouseleave="noticeMove">
					<img class="notice display-block" src="@/assets/img/notice.png" alt="" />
					<div class="notice-num fz12 co-white absolute" v-if="list.length>0">{{list.length}}</div>
					<div class="triangle absolute" v-if="isshownotice">
						<img class="triangle display-block" src="@/assets/img/triangle.png" alt="" />
					</div>
					<div class="absolute notice-area flex-column " v-if="isshownotice">
						<div class="h10 w100"></div>
						<div class="flex-1 bg-white boderra8 flex-column notice-area-inside">
							<div class="flex-1 scroll-area ">
								<el-scrollbar height="380px">
									<div class="notice-item  paddt20 paddb15 " v-for="(item, i) in list" :key="i">
										<div class="display-flex w100">
											<div class="display-flex relative">
												<img class="kefu-notice display-block"
													:src="require(`@/assets/img/${item.content_type == 'comment' ? 'sys_notice' : item.content_type == 'favorite' ? 'sys_notice' : 'kefu-notice'}.png`)"
													alt="" />
												<div class="point absolute top0" v-if="item.isread == 1"></div>
											</div>
											<div class="marl8 flex-1"
												:class="i < list.length - 1 ? 'boderb1-E6E6E6' : ''">
												<div class="align-items-center  " style="font-size: 14px;">
													<span class="co-333333 ">{{item.title}}</span>

												</div>
												<span
													class="one-point co-999999 flex-1 display-block marl8">{{item.content}}</span>
												<div class="mart5  co-999999 paddb9" style="font-size: 14px;">
													{{item.create_time}}
												</div>
											</div>
										</div>
									</div>
									<div class='mart40 co-333333 text-center' v-if="list.length==0">
										暂无任何消息
									</div>

								</el-scrollbar>
							</div>
							<el-popconfirm title="确定清空消息通知吗？" @confirm="sureConfirm">
								<template #reference>
									<div class="cure-pointer remove-notice center fz10 co-999999"
										@click="visible = !visible">清空通知</div>
								</template>
							</el-popconfirm>
						</div>
					</div>
				</div>
				<div class="marl36 cure-pointer" @click="godetails('Home')" v-else>
					<div class="fz17 co-333333">登录/注册</div>
				</div>
				<!-- <div class="fz16 co-333333 align-items-center cure-pointer marl38" @click="toLogin">登录</div> -->
				<div class="marl40 relative h100 center z100" v-if="JSON.stringify(user_info)!='{}'" @mousemove="showUser"
					@mouseleave="isshowUser = false">
					<img class="img_02 display-block cure-pointer" v-if="user_info" :src="user_info.avatar" alt="" />
					<div class="absolute user-operation "  v-if="isshowUser">
						<div class="h51 align-items-center  co-333333" v-if="user_info.is_vip==2">
							<img style="width: 83px; height:22px" class="marl12" src="@/assets/img/user2.png" alt="" />
						</div>
						<div class="h51 align-items-center  co-333333" v-else-if="user_info.is_vip==1">
							<img style="width: 59px; height:22px" class="marl12" src="@/assets/img/user1.png" alt="" />
						</div>
						<div class="h51 align-items-center  co-333333" v-else>
							<img style="width: 59px; height:22px" class="marl12" src="@/assets/img/user0.png" alt="" />
						</div>
						<div v-html="member_center_desc" class="html-box  ">
						</div>

						<div class="flex-row mart10" style="height: 1px;width: 100%;background: #E6E6E6;">

						</div>
						<div class="h51 align-items-center marl12">
							<div class="align-items-center flex-1 ">
								<img style="width: 18px; height:18px" src="@/assets/img/userdownload.png" alt="" />
								<span class="fz14 display-block marl12">免费下载</span>
							</div>
							<div class="align-items-center flex-1 ">
								<img style="width: 18px; height:18px" src="@/assets/img/td_04.png" alt="" />
								<span class="fz14 display-block marl12">免费上课</span>
							</div>
						</div>
						<div class="h51 align-items-center user-operation-item co-333333"
							@click="godetails('Myvip')">
							<span class="fz14 display-block marl12">会员中心</span>
						</div>
						<div class="h51 align-items-center user-operation-item co-333333"
							@click="godetails('Myintegral')">
							<span class="fz14 display-block marl12">我的积分</span>
						</div>
						<div class="h51 align-items-center user-operation-item co-333333" @click="godetails('Myvip')">
							<span class="fz14 display-block marl12">个人中心</span>
						</div>
						<div class="h51 align-items-center user-operation-item co-333333"
							@click="godetails('Assessment')">
							<span class="fz14 display-block marl12">作品评估</span>
						</div>
						<div class="h51 align-items-center user-operation-item co-333333" @click="godetails('Home',1)">
							<span class="fz14 display-block marl12">退出</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="fixed function" style="z-index: 10;">
		<div class="relative">
			<img class="index_bottom display-block cure-pointer" src="@/assets/img/index_top.png" @click="backTop"
				alt="">
			<img class="index_bottom mart5 display-block cure-pointer"
				:src="require(`@/assets/img/${codePopup ? 'code_choose' : 'index_code'}.png`)" alt=""
				@click="showPopup(1)">
			<img class="index_bottom mart5 display-block cure-pointer" @click="showPopup(2)"
				:src="require(`@/assets/img/${servicePopup ? 'kefu_choose' : 'index_kefu'}.png`)" alt="">
			<img class="index_bottom mart5 display-block cure-pointer" src="@/assets/img/pinggu.png" alt=""
				@click="$router.replace({ path:'/Assessment' })">

			<div class="absolute code-popup-box" >
				<div class="align-items-center code-popup"
					:style="`transform: translateX(${codePopup ? '0px' : '340px'})`">
					<div class="flex-column center" v-for="(item,index) in imglist" :key='index'>
						<el-image class="code-pic " fit :src="item.qrcode" @click="toBig(item.qrcode)" alt="">
						</el-image>
						<span class="fz10 co-333333 display-block mart8">{{item.name}}</span>
					</div>

				</div>
			</div>
		</div>
	</div>
	<Preview ref="Preview"></Preview>
	<SureCancelPopup ref="SureCancelPopups" title='是否确定退出登入?' @sureBtn="sureBtn"></SureCancelPopup>

	<el-dialog title="" :lock-scroll='false' v-model="dialogVisible" :center='true' :width='511' :show-close='false'>
		<h3 style="text-align: center;" class="marb30">请绑定手机号</h3>
		<div class="form">
			<!-- //手机号登入 -->
			<div class="center flex-column">
				<div class="phone-input">
					<input type="text" v-model="wxform.phoneNum" class="input fz14 co-333333" maxlength="11"
						placeholder="请输入手机号">
				</div>
				<!-- 验证码登录 -->
				<div class="phone-input display-flex mart30">
					<input type="text" class="input fz14 co-333333 flex-1 marr24" v-model="wxform.code" maxlength="6"
						placeholder="请输入验证码">
					<getCode ref="getCode" :phone='wxform.phoneNum' :type='9' codeStatus="获取验证码">
					</getCode>
				</div>
			</div>
			<!-- 登录按钮 -->
			<div class="login-btn bg-DCFF03 center cure-pointer mart25" @click="registeredsubmit">
				<span class="fz16 co-010085">登录</span>
			</div>
		</div>
	</el-dialog>
	<!-- <chatCustomer :chat_user="chat_user" showClose @close="showChatMask=false"></chatCustomer> -->
	<!-- 聊天弹窗 -->
	<transition name="fast">
		<div class="chat-mask display-flex align-items-center justify-content-center" v-if="showChatMask"
			@click="showChatMask=false">
			<div class="chat-mask-content">
				<chatCustomer :chat_user="chat_user" showClose @close="showChatMask=false"></chatCustomer>
			</div>
		</div>
	</transition>
</template>

<script>
	import {
		ref,
		watch,
		getCurrentInstance
	} from 'vue';
	import {
		useRoute
	} from 'vue-router';
	import Preview from "@/components/Preview.vue";
	import getCode from '@/components/getCode.vue';
	import axios from 'axios';
	import chatCustomer from '@/components/chatCustomer.vue'
	import {
		$on
	} from 'vue-happy-bus'
	export default {
		components: {
			Preview,
			getCode,
			chatCustomer

		},
		name: 'pageheader',
		props: {},
		setup() {
			const req1 = getCurrentInstance();
			const route = useRoute();
			var servicePopup = ref(false); // 是否显示客服弹窗
			var codePopup = ref(false); // 是否显示客服弹窗
			const request = req1.appContext.config.globalProperties;
			watch(() => route.path, () => {
				servicePopup.value = false
				codePopup.value = false

			})

			return {
				servicePopup,
				codePopup,
				request
			}
		},
		data() {

			return {
				wxform: {
					phoneNum: "",
					code: '',
				},
				dialogVisible: false,
				chat_user: {
					type: 'customerMsg',
					id: 1,
					nickname: '在线客服'
				},
				showChatMask: false,
				// img: [
				// 	require("@/assets/img/code.png"),
				// 	require("@/assets/img/code.png"),
				// 	require("@/assets/img/code.png"),
				// 	require("@/assets/img/code.png"),
				// ],
				// imglist: [{
				// 		icon: require("@/assets/img/code.png"),
				// 		titile: '微信客服',
				// 	},
				// 	{
				// 		icon: require("@/assets/img/code.png"),
				// 		titile: '微信客服',
				// 	},
				// 	{
				// 		icon: require("@/assets/img/code.png"),
				// 		titile: '微信客服',
				// 	},
				// 	{
				// 		icon: require("@/assets/img/code.png"),
				// 		titile: '微信客服',
				// 	},

				// ],
				titleList: [{
					name: '干货中心',
					link: '/'
				}, {
					name: '作品集辅导',
					link: '/Teacher'
				}, {
					name: '课程优选',
					link: '/CourseSelection'
				}, {
					name: '关于我们',
					link: '/About'
				}, {
					name: 'APP',
					link: '/DownApp'
				}], // 顶部导航
				selectList: [{
					name: '项目灵感',
					link: '/InspirationPart'
				}, {
					name: '素材市场',
					link: '/MaterialMarket'
				}, {
					name: '研图社',
					link: '/YantuSociety'
				}, {
					name: '竞赛信息',
					link: '/CompetitionInfo'
				}, {
					name: '院校指南',
					link: '/School'
				}], // 干货中心下拉列表
				selectListTwo: [{
					name: '找老师',
					link: '/Teacher'
				}, {
					name: '找机构',
					link: '/Sincerity'
				}], // 作品集下拉列表
				navIndex: -1, // 鼠标滑过下标
				list: [], // 消息列表 1客服通知 2系统通知 3订单通知
				isshownotice: false, // 是否显示通知弹窗
				visible: false, // 清空通知提示弹窗
				isshowUser: false, // 是否显示用户操作
				emojiArr: ['😀', '😁', '😂', '😃', '😄', '😅', '😆', '😉', '😊', '😋', '😎', '😍', '😘', '😗', '😙', '😚',
					'😇', '😐', '😑', '😶', '😏', '😣', '😥', '😮', '😯', '😪', '😫', '😴', '😌', '😛', '😜', '😝',
					'😒', '😓', '😔', '😕', '😲', '😷', '😖', '😞', '😟', '😤', '😢', '😭', '😦', '😧', '😨', '😬',
					'😰', '😱', '😳', '😵', '😡', '😠', '🌹', '🍀', '🍎', '💰', '📱', '🌙', '🍁', '🍂', '🍃', '🌷',
					'💎', '🔪', '🔫', '🏀', '👄', '👍', '🔥', '💪', '👈', '👉', '👆', '👇', '👌', '👍', '👎', '✊'
				], // 表情数组
				content: '', // 输入内容
				showemojis: false, // 是否显示表情弹窗
				user_info: {},
				maskBtn: false,
				bigImgSrc: '',
				datas: {},
				member_center_desc:""
			
			}
		},
		created() {
			if (this.$store.state.token) {
				this.$webSocket.getRecentList(); // 聊天列表
			}
			// 监听最近聊天列表
			$on('onRecentList', list => {
				list.forEach(item => {
					if (item.msg_type == 'msg_type') {
						item.nickname = '在线客服';
					}
				});
				this.chat_list = list;
				let unReadNum = 0;
				list.forEach((item, index) => {
					unReadNum += parseInt(item.unReadNum || 0);
				});
				this.unReadNum = unReadNum;
			});
			let timer = '';
			$on('onMsgResult', data => {
				if (this.classify_index == 0) {
					clearTimeout(timer);
					timer = setTimeout(() => {
						clearTimeout(timer);
						this.$webSocket.getRecentList(); // 聊天列表
					}, 200)
				}
			})
			$on('onMsg', data => {
				if (this.classify_index == 0) {
					clearTimeout(timer);
					timer = setTimeout(() => {
						clearTimeout(timer);
						this.$webSocket.getRecentList(); // 聊天列表
					}, 200)
				}
			})
			
		},
		mounted() {
			if (localStorage.getItem('token')) {
				if (localStorage.getItem('user_info')) {
					this.user_info = JSON.parse(localStorage.getItem('user_info'))
					
				}
			}

			if (this.$store.state.code) {
				let token = localStorage.getItem('token');
				if (token) {
					this.$req({
						method: 'get',
						url: '/api/notice',
						success: res => {
							this.list = res
							console.log(res)
						},
						fail: error => {}
					});
				}
				if (!token) {
					let that = this;
					this.$req({
						method: 'post',
						url: '/api/wechat/wechatLogin',
						data: {
							code: this.$store.state.code
						},
						success: res => {
							localStorage.setItem('token', res.token);
							localStorage.setItem('timeout', res.expire)
							let token = localStorage.getItem('token');
							that.authorize(token)
						},
						fail: error => {
							console.log(error)
							if (error.data.code == 200) {
								localStorage.setItem('token', error.data.token);
								localStorage.setItem('timeout', error.data.expire)
								let token = localStorage.getItem('token');
								that.authorize(token)
							}
							if (error.data.code == 5001) {
								this.dialogVisible = true;
								this.datas = error.data.data;
								console.log(this.datas)
							}
						}
					});
				} else {
					console.log('有用户信息了')
				}
			}
			$on('openadmin', list => {
				let token = localStorage.getItem('token');
				window.requestAnimationFrame(() => {
					if (token) {
						// 客服聊天弹窗
						this.showChatMask = true;
					} else {
						// 显示登录弹窗
						this.$store.commit('setShowLogin', true);
					}
				})
			});
			this.$req({
				method: 'get',
				url: '/api/config/customer',
				success: res => {
					this.imglist =res
					console.log(this.imglist)
				},
				fail: error => {}
			});
			this.$req({
				method: 'get',
				url: '/api/config',
				success: res => {
					// console.log(res)
					let member_center_desc = res.member_center_desc
			
					this.member_center_desc = member_center_desc
				},
				fail: error => {}
			});
		},
		watch: {
			"$store.state.showLogin": function() {
				if (this.$store.state.showLogin) {
					this.$message.warning('您暂未登入,请登入后在操作')
				} else {
					console.log('有登入')
				}
			},
			"$store.state.user_info": function() {
				this.user_info = JSON.parse(localStorage.getItem('user_info'))
			}
		},
		methods: {
			registeredsubmit() {

				let phone = /^1[3456789]\d{9}$/;
				if (!phone.test(this.wxform.phoneNum, "phone")) {
					this.$message.error("请输入正确的手机号")
					return false;
				}
				this.$req({
					method: 'post',
					url: '/api/login/phone',
					data: {
						phone: this.wxform.phoneNum,
						code: this.wxform.code,
						...this.datas
					},
					success: res => {
						console.log(res)
						localStorage.setItem('token', res.token);
						localStorage.setItem('timeout', res.expire)
						let token = localStorage.getItem('token');
						this.authorize(token)
						this.dialogVisible = false;
						this.$message({
							type: 'success',
							message: '绑定手机号成功'
						});
					},
					fail: error => {
						// this.dialogVisible = false;
						this.$message.error(error.data.message);
					},

				});
			},
			authorize(token) {
				let that = this;
				axios({
					url: this.request.$httpUrl + '/api/member',
					method: 'get',
					headers: {
						'Content-Type': 'application/json',
						"Authorization": token,
					},
				}).then(function(res) {
					that.$store.commit('setUserInfo', res.data.data);
					that.$message({
						type: 'success',
						message: '授权登入成功'
					});
				}).catch(function(error) {
					console.log(error)
				});
			},
			toBig(url) {
				this.$refs.Preview.bigImgShow(url)
			},
			// 选择表情
			chooseEm(e) {
				if (this.content) {
					this.content = `${this.content}${this.emojiArr[e]}`
				} else {
					this.content = `${this.emojiArr[e]}`
				}
			},
			// 导师在线咨询
			onService() {
				this.servicePopup = true
			},
			// 回到顶部
			backTop() {
				window.scrollTo(0, 0)
				if (this.servicePopup) {
					this.servicePopup = false
				}
				if (this.codePopup) {
					this.codePopup = false
				}
			},
			// 显示用户操作弹窗
			showUser() {
				this.isshowUser = true
			},
			// 显示code弹窗
			showPopup(e) {
				if (e == 1) {
					if (this.servicePopup) {
						this.servicePopup = false
					}
					this.codePopup = !this.codePopup
				} else {
					let token = localStorage.getItem('token');
					window.requestAnimationFrame(() => {
						if (token) {
							// 客服聊天弹窗
							this.showChatMask = true;
						} else {
							// 显示登录弹窗
							this.$store.commit('setShowLogin', true);
						}
					})
					// if (this.codePopup) {
					// 	this.codePopup = false
					// }
					// this.servicePopup = !this.servicePopup
				}
			},
			// 清空消息通知
			sureConfirm() {
				this.isshownotice = false
				this.visible = false
				this.list = [];
			},
			sureBtn() {
				this.$req({
					method: 'post',
					url: '/api/logout',
					success: res => {
						this.$refs.SureCancelPopups.close();
						this.$store.commit('clearUserInfo')
						this.$message({
							type: 'success',
							message: '成功退出!'
						});
						this.$router.push({
							name: 'Home',
						})
					},
					fail: error => {}
				});
			},
			godetails(url, id) {
				if (id == 1) {
					this.$refs.SureCancelPopups.open();

					return
				}
				this.$router.push({
					name: url,
				});

			},
			// 消息通知消息鼠标移出隐藏
			noticeMove() {
				if (!this.visible) {
					this.isshownotice = false
				}
			},
			// 鼠标滑过
			navMove(e) {
				this.navIndex = e
			},
			// 鼠标离开
			navLeave() {
				this.navIndex = -1
			},
			// 导航跳转
			navChoose(e) {
				this.$router.replace({
					path: e
				})
			},
			// 干货中心
			childrenClick(e) {
				this.$router.push({
					path: e
				})
			},
			// 退出

		}
	};
</script>

<style lang="less" scoped="scoped">
	.html-box{
		padding: 0px 20px;
	}
	.html-box img {
	
		display: block;
		margin: 5px auto;
		width: 100% !important;
		height: auto !important;
	}
	
	.html-box p {
	
		width: 100%;
		word-break: break-word;
		font-size: 18px;
		font-weight: 500;
		color: #000000;
		margin: 30px 0;
	}
	
	.html-box span {
	
		max-width: 100%;
		word-break: break-word;
		font-size: 18px;
		font-weight: 500;
		color: #000000;
		margin: 30px 0;
	}
	/* 客服聊天弹窗 */
	.chat-mask {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		position: fixed;
		left: 0;
		top: 0;

		z-index: 100;

		.chat-mask-content {
			width: 800px;
			height: 700px;
			background-color: #fff;
		}
	}

	html {
		overflow: hidden;
	}

	.no_choose {
		width: 21px;
		height: 21px;
	}



	.login_logos {
		width: 150px;
		width: 150px;
	}

	.login_area {
		// height: 500px;
		// width: 450px;
		background: #FFFFFF;

		.login_logo {
			width: 58px;
			height: 58px;
		}

		.line {
			height: 2px;
			top: 30px;
			left: 5px;
			width: 70px;
			background-color: #010085;

		}

		.line1 {
			height: 2px;
			top: 30px;
			left: 5px;
			width: 80px;
			background-color: #010085;

		}

		.login-way:hover {
			cursor: pointer;
		}

		.register:hover {
			cursor: pointer;
		}

		.wechat_login {
			width: 34px;
			height: 34px;
		}

		.wechat_login:hover {
			cursor: pointer;
		}
	}

	.login-btn {
		width: 300px;
		height: 50px;
		border-radius: 6px;
		margin: 0 auto;
		margin-top: 60px;
	}

	.phone-input {
		width: 268px;
		height: 50px;
		padding: 0px 16px;
		border-radius: 6px;
		border: 1px solid #999999;

		input:-internal-autofill-selected {
			appearance: menulist-button;
			background-color: transparent !important;
			background-image: none !important;
			color: -internal-light-dark(black, white) !important;
		}
	}

	.fast-enter-active,
	.fast-leave-active {
		transition: opacity 0.35s;
	}

	.fast-enter-from,
	.fast-leave-to {
		opacity: 0;
	}

	.input {
		height: 100%;
		padding: 0px;
		margin: 0px;
		outline: none;
		border: 0;
	}

	.input::-webkit-input-placeholder {
		color: #999999;
	}

	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
		margin: 0;
		-moz-appearance: textfield;

	}

	.em-box {
		width: 320px;
		height: 150px;
		padding: 13px;
		overflow-y: hidden;
		background: #FFFFFF;
		right: 72px;
		top: -141px;

		.em-box-inside {
			border-bottom: 1px solid #E6E6E6;

			.em-item {
				width: 30px;
				height: 30px;
				border-left: 1px solid #E6E6E6;
				border-top: 1px solid #E6E6E6;
				border-right: 1px solid #E6E6E6;
			}
		}
	}

	.send {
		width: 96px;
		height: 36px;
	}

	.se-02 {
		width: 24px;
		height: 24px;
	}

	.desc {
		width: 100%;
		height: 136px;
		outline: none;
		resize: none;
		border: 0;
		margin: 0;
		padding: 0;
	}

	.serve-head {
		width: 40px;
		height: 40px;
	}

	.content {
		max-width: 600px;
		padding: 13px 12px;
	}

	.consult-box {
		width: 1173px;
		height: 886px;
		background: #F9FAFD;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
		margin: auto;
		z-index: 10000;
		transition: all 0.3s;

		.consult-logo {
			width: 58px;
			height: 58px;
		}

		.se-01 {
			padding: 15px 11px;
		}

		.se-01-icon {
			width: 14px;
			height: 14px;
		}
	}

	.se_01 {
		width: 14px;
		height: 14px;
	}


	.user-operation {
		width: 320px;
		// height: 120px;
		background: #FFFFFF;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
		left: -45px;
		top: 80px;

	}

	.user-operation-item:hover {
		cursor: pointer;
		background: #F7F7FB;
		color: #010085;
	}

	.user-operation-item .clo {
		color: #010085;
		font-weight: 500;
		font-family: PingFang SC;
	}

	.code-popup-box {
		// width: 340px;
		height: 118px;
		left: -166px;
		top: 26px;
		overflow: hidden;
		// box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);	
	}

	.code-popup {
		// width: 300px;
		height: 118px;
		background: #FFFFFF;
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
		border-radius: 6px;
		padding: 0 20px;
		transition: all 0.3s;

		.code-pic {
			width: 60px;
			height: 60px;
		}
	}

	// 轮播
	.swiper {
		min-width: 1200px;
		height: 680px;

		.swiper-pic {
			height: 680px;
		}
	}

	// 顶部导航
	.nav_area {
		background: #FFFFFF;

		.nav {
			min-width: 1200px;
			margin: 0 auto;

			.logo {
				width: 44px;
				height: 44px;
			}

			.title_item {
				padding: 0 26px;
			}

			.title_item_01:hover span {
				color: #010085;
			}

			.i_01 {
				width: 10px;
				height: 10px;
				transition: all 0.3s;
			}

			.select {
				overflow: hidden;
				transition: all 0.3s;
				z-index: 1000001;
			}

			.select-item {
				height: 40px;
			}

			.select-item:hover {
				cursor: pointer;
				color: #010085;
			}

			.select_01 {
				height: 200px;
				top: 80px;
				box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
			}

			.search-box {
				width: 252px;
				height: 34px;
				background: #F7F7F7;
				border-radius: 4px;

				.search_icon {
					width: 18px;
					height: 18px;
				}

				.search-input {
					outline: none;
					border: 0;
					background: #F7F7F7;
					margin-left: 12px;
				}
			}

			.notice {
				width: 22px;
				height: 22px;
			}

			.notice-num {
				padding: 0 6px;
				height: 14px;
				background: #EF3E00;
				border-radius: 7px;
				top: 25px;
				right: -10px;
			}

			.triangle {
				width: 27px;
				height: 15px;
				top: 76px;
				right: -3px;
			}

			.notice-area {
				width: 368px;
				// height: 418px;
				top: 80px;
				left: -170px;

				.notice-area-inside {
					box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
				}

				.scroll-area {
					border-radius: 8px 8px 0 0;

					.notice-item {
						padding: 8px 15px;

						.point {
							width: 6px;
							height: 6px;
							background: #FE3000;
							border-radius: 50%;
							right: 4px;
						}

						.kefu-notice {
							width: 32px;
							height: 32px;
						}
					}
				}

				.remove-notice {
					height: 30px;
				}
			}
		}
	}

	input::-webkit-input-placeholder {
		color: #999999;
		font-size: 16px;
	}

	.rotate {
		transform: rotate(180deg);
	}

	.function {
		bottom: 200px;
		right: 40px;

		.index_bottom {
			width: 44px;
			height: 44px;
		}
	}

	.el-scrollbar__bar.is-horizontal {
		height: 0 !important;
		left: 0 !important;
	}

	.img_02 {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	textarea::-webkit-input-placeholder {
		color: #999999;
		font-size: 10px;
	}
</style>
