<template>
	<div class="code-btn center" @click="getCode">
		<span class="co-010085 fz14"> {{codeShowText}}{{btnMsg}}</span>
	</div>
		<div v-loading.fullscreen.lock="fullscreenLoading"></div>
</template>

<script>
	export default {
		props: {
			codeStatus: {
				type: String,
				default: '获取验证码',
			},
			phone: {
				type: [Number, String],

			},
			type: { //1:登录 2:忘记密码 3:注册 4:微信登录绑定手机号码 
				type: [Number, String],
				default: 1
			}
		},
		data() {
			return {
				codeShowText: '', //文字
				btnMsg: '',
				disable: true,
				timer: null,
				fullscreenLoading: false,
			}
		},
		created() {
			this.codeShowText = this.codeStatus
		},
		methods: {
			// 获取验证码
			getCode() {
				this.fullscreenLoading=true;
				let phone = /^1[3456789]\d{9}$/;
				if (!phone.test(this.phone, "phone")) {
					this.$message.error("请输入正确的手机号")
					return false;
				}
				if (!this.timer) {
					this.$req({
						method: 'post',
						url: '/api/sms/send',
						data:{
							phone:this.phone,
							type:this.type
						},
						success: res => {
							
							this.btnMsg = 's后重新获取';
							this.disable = false;
							const TIME_COUNT = 60;
							this.codeShowText = TIME_COUNT;
							this.timer = setInterval(() => {
								if (this.codeShowText > 0 && this.codeShowText <= TIME_COUNT) {
									this.codeShowText--;
								} else {
									clearInterval(this.timer);
									this.timer = null;
									this.disable = true;
									this.codeShowText = '重新获取';
									this.btnMsg = '';
								}
							}, 1000);
							this.$message.success('发送成功');
							this.fullscreenLoading=false;
						},
						fail: error => {
							this.$message.error(error.data.message);
						}
					});
					
				}

			},
			// 清除定时器
			clearTimer() {
				if (this.timer) {
					this.times = 60
					this.codeShowText = '重新获取'
					clearInterval(this.timer)
				}
			}
		}
	};
</script>

<style lang="less" scope>
	.code-btn {
		height: 100%;
	}

	.code-btn:hover {
		cursor: pointer;
	}
</style>
