<template>
	<div class="display-flex flex-wrap">
		<div class="item boderra8 bg-white marb20 cure-pointer" v-for="(item, i) in lists" :key="i" @click="toDetail(item.id)" >
			<div class="">
				<!-- <img class="item-pic display-block" :src="item.image" alt="" /> -->
				<el-image class="item-pic display-block"  :src="item.image" lazy  fit="cover"></el-image>
			</div>
			<div class="paddlr16">
				<div class="paddt20 fz20 co-333333 font-blod one-point l20">{{item.title}}</div>
				<div class="justify-content-center-between paddb28 mart15">
					<span class="fz16 co-7D7D7D l16 display-block">{{item.favorite}}推荐   {{item.comment}}评论</span>
					<span class="fz16 co-7D7D7D l16 display-block">{{item.create_time}}</span>
				</div>
			</div>
			<div class="paddlr16 marb10" v-if="item.avatar || item.nickname">
				<div class="align-items-center ">
					
					<img class="icon_02 "  :src="item.avatar" alt="" />
					<text class="fz16 co-222222 font-blod marl10">{{item.nickname || ''}}</text>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import {
	
		getCurrentInstance,
	
	} from 'vue';
	export default {
		props: {
			list: {
				type: Array,
				default () {
					return []
				}
			}
		},
		
		data() {
			return {
				lists: []
			}
		},
		watch: {
			list(newval) {
				this.lists = newval
				
			},
		},
		created() {
			this.lists = this.list
		
		},
		methods: {
			// 跳转详情
			toDetail(id) {
				this.$router.push({
					path:'/Detail',
					query: { type: 5,
							id:id
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.item {
		width: 285px;
		margin-right: 20px;
		box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
		
		.item-pic {
			width: 285px;
			height: 220px;
			border-radius: 8px 8px 0px 0px;
		}
		.icon_02 {
			width: 20px;
			height: 20px;
			border-radius: 50%;
		}
	}
	
	.item:nth-child(4n) {
		margin-right: 0;
	}
</style>
