<template>
	<router-view />
</template>

<script>
	import {
		useRoute
	} from 'vue-router';
	import {
		watch,
		getCurrentInstance
	} from "vue";
	import {
		useStore
	} from 'vuex';
	import {
		v4
	} from 'uuid'
	import {
		$on,
		$emit
	} from 'vue-happy-bus'
	export default {
		data() {
			return {
				
			}
		},
		setup() {

			const route = useRoute()
			const store = useStore()
			watch(() => route.path, () => {

				store.commit('setBg', route.path)
				// 路由跳转后html overflow自动变成hidden，监听路由变化 设置html overflow 为auto，是页面可以自动滚动
				document.querySelector("html").setAttribute("style", "overflow: auto !important;")
				
			})
		},
		created() {
			if(!localStorage.getItem('uuid')){
				let uuid = v4()
				localStorage.setItem('uuid',uuid)
				console.log(uuid,'没有就生成')
			}
		},
		mounted() {
			
				// this.$router.push({
				// 	name: 'Myvip',
				// 	params: { type:2}
				// })
				// this.$router.push({ path: '/Myvip', query: { type: 1 } })
			this.$webSocket.config({
				
				// 登录成功
				onLoginSuccess: (res) => {
					// 获取未读消息数
					console.log(res);
					// todo
					$emit('openshows','qwer');
				},
				// 接收到最近会话列表数据
				onRecentList: (res) => {
					console.log(res, '会话列表数据')
					let list = res.data;
					let unReadNum = 0;
					list.forEach((item, index) => {
						unReadNum += parseInt(item.unReadNum || 0);
					});
					// res.unReadNum = unReadNum;
					$emit('onRecentList', res.data);
				},
				// 接收到好友列表数据
				onFriendList: (res) => {
					$emit('onFriendList', res);
				},
				// 接收到聊天记录数据
				onMsgRecordHistory: (res) => {
					console.log(res,'聊天记录')
					$emit('onMsgRecordHistory', res);
				},
				// 消息发送结果回调
				onMsgResult: (res) => {
					$emit('onMsgResult', res);
				},
				// 接收到消息
				onMsg: (res) => {
					console.log(res,'接收到消息')
					$emit('onMsg', res);
					
					// 播放消息提示音
					// document.querySelector("#myAudio").play();
					// this.$webSocket.getRecentList();
				},
				// // 接收到客服列表
				onCustomerGroupList: (res) => {
					console.log(res,'客服消息')
					$emit('onCustomerGroupList', res);
				},
				// 接收到其他消息回调
				onOtherMessage: (res) => {
					$emit('onOtherMessage', res);
				},
				// 关闭回调
				onClose: (res) => {
					// todo
					console.log(res,'关闭回调')
				},
				// 错误回调
				onError: (res) => {
					console.log(res,'错误回调')
					// todo
				}
			});

			this.configWebsocket();
			this.$get({
				url: '/api/index/customer',
				success: res => {
					localStorage.setItem('adminid', res.id)
				}
			})
			
		},
		watch: {
			"$store.state.user_info": function() {
				this.configWebsocket();
			}
		},
		methods: {
			// 配置websocket
			configWebsocket() {
				let user_info = this.$store.state.user_info;
				if (user_info && user_info.id) {
					if (!this.$webSocket.username || this.$webSocket.password) {
						this.$webSocket.config({
							username: user_info.im_username,
							password: user_info.im_password,
						})
						if (this.$webSocket.url) {
							this.$webSocket.checkConnect();
						} else {
							this.$get({
								url: '/api/index/websocket',
								success: res => {
									console.log(res)
									this.$webSocket.config({
										url: res
									});
									this.$webSocket.checkConnect();
								}
							})
						}
					}
				}
			},
			// 关闭websocket
			closeWebsocket() {
				try {
					this.$webSocket.close();
				} catch (e) {
					//TODO handle the exception
				}
			}

		}
	}
</script>

<style>
	#app {
		width: 100%;
		min-height: 100vh;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	html {
		min-height: 100vh;
	}

	body {
		margin: 0;
		min-height: 100vh;
		min-width: 1200px;
		padding: 0 !important;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	#nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}

	/* // 改变组件样式 */
	.el-pager li {
		font-size: 14px !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
		line-height: 40px !important;
		padding: 0 !important;
		color: #999999 !important;
	}

	.btn-prev,
	.btn-next {
		width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
	}

	.el-pager .active {
		background: #DCFF03 !important;
		color: #010085 !important;
		border: 1px solid #DCFF03 !important;
	}

	.html-box {
		padding: 20px 20px;
	}

	.html-box img {

		display: block;
		margin: 5px auto;
		width: 100% !important;
		height: auto !important;
	}

	.html-box p {

		width: 100%;
		word-break: break-word;
		font-size: 18px;
		font-weight: 500;
		color: #000000;
		margin: 30px 0;
	}

	.html-box span {

		max-width: 100%;
		word-break: break-word;
		font-size: 18px;
		font-weight: 500;
		color: #000000;
		margin: 30px 0;
	}
</style>
