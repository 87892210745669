<template>
	<div class="display-flex flex-wrap">
		<div class="item boderra8 bg-white marb20 cure-pointer" v-for="(item, i) in lists" :key="i" @click="toDetail(item.id)">
			<div class="">
				<!-- <img class="item-pic display-block" :src="item.image" alt="" /> -->
				<el-image class="item-pic display-block"  :src="item.image" lazy fit="cover"></el-image>
			</div>
			<div class="paddlr16   marb22 mart10 ">
				<div class="   fz20 co-333333     two-point font-blod">{{item.title}}</div>
				<!-- <div class="w100 paddb28"></div> -->
			</div>
			<div class="paddlr16 marb10" v-if="item.avatar || item.nickname">
				<div class="align-items-center ">
			
					<img class="icon_02 "  :src="item.avatar" alt="" />
					<text class="fz16 co-222222 font-blod marl10">{{item.nickname || ''}}</text>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			// 数据列表
			list: {
				type: Array,
				default () {
					return []
				}
			}
		},
		data() {
			return {
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				lists: ''
			}
		},
		watch: {
			list(newval) {
				this.lists = newval
		
			},
		},
		methods: {
			// 跳转详情
			toDetail(id) {
				this.$router.push({
					path:'/Detail',
					query: { type: 2,
							id:id
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.item {
		width: 285px;
		margin-right: 20px;
		box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
		padding-bottom: 10px;
		.item-pic {
			width: 285px;
			height: 220px;
			border-radius: 8px 8px 0px 0px;
		}
		
		.draw-content {
			height: 50px;
			overflow: hidden;
		}
		.icon_02 {
			width: 20px;
			height: 20px;
			border-radius: 50%;
		}
	}
	.item:nth-child(4n) {
		margin-right: 0;
	}
	
</style>
