<template>
	<div class="chat-box" @click.stop="showEmojis=false">
		<div class="h104 align-items-center bg-white relative paddlr10">
			<img class="consult-logo display-block marl33" src="@/assets/img/login_logo.png" alt="">
			<div class="display-flex flex-column marl19">
				<span class="co-333333 fz20 display-block">建筑设计学习平台</span>
				<span class="fz14 co-010085 display-block mart8">在线客服咨询</span>
			</div>
			<div class="absolute se-01 right0 top0 cure-pointer paddr20 paddt20" v-if="showClose" @click="clickClose">
				<img class="display-block se-01-icon" src="@/assets/img/se_01.png" alt="">
			</div>
		</div>
		<div class="chat-content" ref="scrollbox" @scroll="scrollBox" @mousewheel.stop.prevent="mousewheel">
		    <ul class="chat-record-list">
		        <li className="flex-1"></li>
		        <template v-for="item in list" :key="item.id">
		            <li class="chat-record" :class="item.from_uid == myInfo.id ? 'me' : 'you'">
		                <div class="head image"><img :src="item.avatar"></div>
		                <div class="info flex-1 ">
		                    <div class="name" v-if="item.from_uid == myInfo.id">{{item.nickname}}</div>
		                    <div class="name" v-else>{{chat_user.nickname}}</div>
		                    <!-- 文字 -->
		                    <div class="txt more_row" v-if="item.type == 1">{{item.content}}</div>
		                    <!-- 图片 -->
		                    <div class="pic image" v-if="item.type == 2" @click="see_image_url=item.content"><img
		                            :src="item.content"></div>
		                    <!-- 语音 -->
		                    <div class="audio pointer" v-if="item.type == 3" @click="playAudio(item)">
		                        <span class="audio-fill" :style="{width:item.ext.time*3+20+'px'}"></span>
		                        <span class="audio-time"
		                            v-if="item.msg_id != playAudioData.msg_id">{{item.ext.time}}'</span>
		                        <template v-if="item.msg_id == playAudioData.msg_id">
		                            <div class="image" v-if="playAudioData.loading"><img
		                                    src="@/assets/img/loading2.png">
		                            </div>
		                            <span class="audio-time" v-else>{{playAudioData.currentTime}}'</span>
		                        </template>
		                    </div>
		                    <!-- 发送时间 -->
		                    <div class="time">{{item.time}}</div>
		                </div>
		            </li>
		        </template>
		        <li>
		            <div class="loading image" v-if="loading">
		                <img src="@/assets/img/loading2.png">
		            </div>
		            <div class="no_more fz14 grey" v-if="isNoMore && list.length > 10">没有更多了</div>
		        </li>
		    </ul>
		</div>
		

		<!-- 输入框 -->
		<div class="chat-foot">
			<!-- 菜单 -->
			<textarea class="textarea" placeholder="很高兴为您服务，请描述您的问题..." autosize resize='none' v-model="content"
				@keydown.stop="keyEnter"></textarea>

			<div class="btn-box flex-row-center-sb">
				<div class="chat-menu flex-row-center-start">
					<!-- 选择表情 -->
					<div class="chat-menu-item pointer" @click.stop="showEmojis=!showEmojis"><img
							src="@/assets/img/se_03.png"></div>
					<!-- 发送图片 -->
					<el-upload class="avatar-uploader" action="https://yunansi.togy.com.cn/api/upload"
						:show-file-list="false" :on-success="handleImageSuccess">
						<div class="chat-menu-item pointer"><img src="@/assets/img/se_02.png"></div>
					</el-upload>
					<!-- 表情列表 -->
					<div class="emojis-box" v-if="showEmojis">
						<ul @click.stop="()=>{}">
							<template v-for="item in emojis" :key="item">
								<li class="emoji-item" @click="addEmoji(item)">{{item}}</li>
							</template>
						</ul>
					</div>
				</div>
				<div class="send" @click.stop="sendTxt">发送</div>
			</div>
		</div>
	</div>

	<transition name="fast">
		<div class="see-image-mask flex-row-center-center" v-if="see_image_url" @click.stop="see_image_url=''">
			<img :src="see_image_url" @click.stop="()=>{}">
		</div>
	</transition>
	<audio id="voice" :src="playAudioData.url" @timeupdate="timeupdate" @ended="playEnd">
		当前浏览器不支持audio
	</audio>
</template>

<script>
	import {
		$on
	} from 'vue-happy-bus'
	import emojis from '../common/emojis.js';
	export default {
		name: 'chatCustomer',
		props: {
			chat_user: {
				type: Object,
				default: function() {
					return {}
				}
			},
			showClose: {
				type: Boolean,
				default: false
			}
		},
		emits: {
			close: null
		},
		data() {
			return {
				id: '',
				temp_customer_id: '',
				myInfo: {}, // 我的聊天身份信息
				list: [],
				size: 20,
				loading: false,
				isNoMore: false,
				see_image_url: '', // 查看大图的链接
				content: '', // 输入的文字
				oldest_id: '',
				can_update: true, // 是否可以更新数据到聊天记录上，正在浏览历史记录就先不更新
				temp_list: [], // 不能更新数据到聊天记录上时，先将数据存起来
				emojis: emojis, // 表情列表
				showEmojis: false,
				playAudioData: {
					msg_id: 0,
					url: '',
					currentTime: 0
				} // 播放的语音数据
			}
		},
		created() {
			this.list = [];
			this.myInfo = this.$webSocket.info;
			// 监听获取客服列表
			// $on('onCustomerGroupList', list => {
			// 	console.log('客服列表','---')
			
			// 		// let customer = list[0];
			// 		// let id = customer.user_group_id;
			// 		// this.id = id;
			// 		// this.temp_customer_id = customer.id;
			// 		// if (id) {
			// 		// 	this.getRecordList(); // 获取消息记录
			// 		// 	// 清空未读消息
			// 		// 	this.$webSocket.readGroupMsg({
			// 		// 		data: {
			// 		// 			uid: id
			// 		// 		}
			// 		// 	});
			// 		// }
			// 	this.getRecordList(); // 获取消息记录
			// });
			this.getRecordList(); // 获取消息记录
			// 监听获取到聊天记录
			$on('onMsgRecordHistory', list => {
				console.log(list, '消息列表')
				list.forEach(item => {
					if (item.ext && typeof item.ext === 'string'&&item.ext!='ext') {
						item.ext = JSON.parse(item.ext);
					}
				});
				this.setRecordList(list);
				if (list.length > 0) {
					this.oldest_id = this.list[this.list.length - 1].msg_id;
				}
			});
			// 消息发送结果
			$on('onMsgResult', data => {
				console.log(data, '消息发送结果')
				if (data.ext && typeof data.ext == 'string') {
					data.ext = JSON.parse(data.ext);
				}
				if (this.temp_list.length > 0) {
					this.temp_list.push(data);
					this.list = this.temp_list.reverse().concat(this.list);
				} else {
					this.list = [data].concat(this.list);
				}
				this.$refs.scrollbox.scrollTop = 0;
			});
			// 收到消息
			$on('onMsg', data => {
				console.log(data, '收到消息-----------')
				if (data.msg_type == "msg") {
					if (data.ext && typeof data.ext === 'string') {
						data.ext = JSON.parse(data.ext);
					}
					if (this.can_update) {
						if (this.temp_list.length > 0) {
							this.temp_list.push(data);
							this.list = this.temp_list.reverse().concat(this.list);
						} else {
							this.list = [data].concat(this.list);
						}
						this.$refs.scrollbox.scrollTop = 0;
					} else {
						this.temp_list.push(data);
					}
				} else {
					// 不是客服消息，不用管
				}
			});
			// 获取客服列表
			// this.$webSocket.getCustomerGroupList();

		},
		methods: {
			// 播放进度
			timeupdate(e) {
				if (e.target.currentTime == 0) return false;
				let num = parseInt(e.target.currentTime);
				this.playAudioData.currentTime = num;
				this.playAudioData.loading = false;
			},
			// 播放完毕
			playEnd() {
				this.playAudioData = {
					msg_id: 0,
					url: '',
					currentTime: 0,
					loading: false
				}
			},
			// 播放音频
			playAudio(item) {
				// 暂停
				if (this.playAudioData.msg_id && this.playAudioData.msg_id == item.msg_id) {
					this.playAudioData = {
						msg_id: 0,
						url: '',
						currentTime: 0,
						loading: false
					}
				} else {
					// 播放
					this.playAudioData = {
						msg_id: item.msg_id,
						url: item.content,
						currentTime: 0,
						loading: true
					}
					window.requestAnimationFrame(() => {
						document.querySelector("#voice").play();
					});
				}
			},
			// 监听keydown
			keyEnter(e) {
				// Ctrl + Enter,发送
				if ( e.keyCode === 13) {
					e.preventDefault(); 
					this.sendTxt();
				}
			},
			// 添加表情
			addEmoji(emoji) {
				this.content += emoji;
			},
			// 发送文字
			sendTxt() {
				this.showEmojis = false;
				if (!this.content) {
					this.$message.warning("请输入要发送的内容");
					return false;
				}
				let user_info = JSON.parse(localStorage.getItem('user_info'))
				let data = {
					type: 1,
					content: this.content,
					ext: '',
					msg_id:1,
					to_uid:1,
					from_uid:user_info.id,
					msg_type: "msg"
				}
				this.can_update = true;
				this.sendWebIMMessage(data);
				window.requestAnimationFrame(() => {
					this.content = '';
				});
			},
			handleImageSuccess(res) {
				let url = res.data;
				if (url) {
					let data = {
						type: 2,
						content: url,
						ext: {
							image: url
						},
						to_uid: 1, // 对方用户id
					};
					this.sendWebIMMessage(data); // 发送
				}
			},
			// 发送webSocket消息
			sendWebIMMessage(data) {
			console.log(data)
				// 有确定的客服
				this.$webSocket.sendCustomerMsg({
					data: {
						
						action:'msg',
						...data,

					}
				});
				
			},
			// 获取聊天记录
			getRecordList() {
				if (this.loading || this.isNoMore) return false;
				this.loading = true;
				this.$webSocket.getMsgRecordHistory({
					data: {
						to_uid: 1, // 对方用户id
						msg_type: 'msg', // 用户msg ，客服customerMsg
						msg_id:''
					}
				});
			},
			// 渲染聊天记录
			setRecordList(list) {
				console.log(list,'>?/')
				if (list.length < this.size) {
					this.isNoMore = true;
				}
				this.list = [...this.list, ...list.reverse()];
				window.requestAnimationFrame(() => {
					this.loading = false;
				});
			},
			// 鼠标滚轮滚动
			mousewheel(e) {
				let top = this.$refs.scrollbox.scrollTop;
				if (e.deltaY > 0) {
					// 下
					this.$refs.scrollbox.scrollTop = top - 100 > 0 ? top - 100 : 0;
				} else {
					// 上
					this.$refs.scrollbox.scrollTop = top + 100;
				}
			},
			// 滚动事件
			scrollBox(e) {
				if (this.loading || this.isNoMore) return false;
				let child = e.target;
				if (child.scrollTop + child.offsetHeight >= child.scrollHeight - 100) {
					// 加载更多
					// this.getRecordList();
				}
				if (child.scrollTop > 50) {
					this.can_update = false;
				} else {
					this.can_update = true;
				}
			},
			clickClose() {
				this.$emit("close");
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.head{
		width: 30px;
		height: 30px;
		img{
			width: 100%;
			height: 100%;
		}
	}
	.more_row{
	padding-top: 10px;
	}
	.flex-row-center-center {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.flex-row-center-sb {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.flex-row-center-start {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	.se-01-icon {
		width: 20px;
		height: 20px;
	}

	.chat-box {
		width: 100%;
		height: 800px;
		background: #F9FAFD;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
		margin: auto;
		z-index: 10;
		transition: all 0.3s;
		background-color: #fff;
		position: relative;
		display: flex;
		flex-direction: column;
	}

	.close {
		width: 30px;
		height: 30px;
		position: absolute;
		right: 17px;
		top: 17px;
	}

	.chat-header {
		padding: 20px;
		font-size: 18px;
		border-bottom: 1px solid #eee;
	}

	.chat-content {
		flex: 1;
		font-size: 16px;
		background-color: #F9FAFD;
		overflow: hidden;
		overflow-y: auto;
		position: relative;
		direction: rtl;
		transform: rotate(180deg);

		.chat-record-list {
			min-height: 100%;
			overflow: hidden;
			display: flex;
			flex-direction: column;
		}

		.chat-record {
			margin: 10px 0;
			direction: ltr;
			text-align: left;
			transform: rotate(180deg);
			display: flex;

			.head {
				margin: 1px 10px 0;
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}

			.info {
				display: flex;
				flex-direction: column;
				overflow: hidden;
			}

			.name {
				font-size: 14px;
				color: #999;
			}

			.txt {
				margin-top: 3px;
				padding: 5px 10px;
				width: auto;
				max-width: 70%;
				background-color: #fff;
				border-radius: 5px;
				white-space: pre-line;
				text-align: justify;
				    word-wrap: break-word;
				    word-break: normal;
			}

			.audio {
				margin-top: 3px;
				padding: 5px 10px;
				width: auto;
				max-width: 450px;
				background-color: #fff;
				border-radius: 5px;
				display: flex;

				.image {
					width: 25px;
					height: 25px;
				}
			}

			.pic {
				margin-top: 3px;
				width: auto;
				height: auto;
				max-width: 100px;
				max-height: 100px;
				background-color: #fff;
				overflow: hidden;
				// margin-top: 10px;
				img{
					width: 100%;
					height: 100%;
					margin-top: 10px;
					// margin-bottom: 10px;
				}
			}

			.time {
				font-size: 12px;
				color: #999;
				opacity: 0;
				
			}

			&:hover {
				.time {
					opacity: 1;
				}
			}

			&.you {
				justify-content: flex-start;

				.head {
					order: 1;
				}

				.info {
					order: 2;
					align-items: flex-start;
				}
			}

			&.me {
				justify-content: flex-end;
				padding: 0 10px;

				.head {
					order: 2;
				}

				.info {
					order: 1;
					align-items: flex-end;
				}
			}
		}

		.loading {
			margin: auto;
			width: 30px;
			height: 30px;

			img {
				animation: changeright 3s linear infinite;
			}
		}

		.no_more {
			line-height: 2;
			transform: rotate(180deg);
			text-align: center;
		}
	}

	ul,
	li {
		list-style: none;
		margin: 0;
		padding: 0
	}

	@keyframes changeright {
		0% {
			-webkit-transform: rotate(0deg);
		}

		50% {
			-webkit-transform: rotate(180deg);
		}

		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	.chat-menu {
		padding: 0 15px;
		height: 44px;
		border-top: 1px solid #eee;
		position: relative;

		.chat-menu-item {
			margin-right: 3px;
			width: 32px;
			height: 32px;
		}

		.emojis-box {
			padding: 5px 2px 5px 5px;
			width: 460px;
			height: 230px;
			border: 1px solid #ddd;
			background-color: #fff;
			box-shadow: 0 0 10px rgba(100, 100, 100, 0.1);
			position: absolute;
			left: 5px;
			bottom: 83px;
			z-index: 10;

			ul {
				width: 100%;
				height: 100%;
				overflow: hidden;
				overflow-y: auto;
				display: flex;
				flex-flow: row wrap;
			}

			li {
				box-sizing: border-box;
				width: 26px;
				height: 26px;
				line-height: 24px;
				border: 1px solid #fff;
				font-size: 16px;
				text-align: center;
				cursor: pointer;

				&:hover {
					border: 1px solid #ccc;
				}
			}
		}
	}

	.chat-foot {
		padding: 0 15px 15px;
		flex-wrap: wrap;

		.textarea {
			display: block;
			// padding: 10px;
			margin: 10px 0;
			width: 100%;
			height: 80px;
			background-color: #ffffff;
			border-radius: 5px;
			font-size: 16px;
			border: none;
			outline: none;

		}

		.btn-box {
			margin-top: 15px;
		}

		.send {
			padding: 0 30px;
			height: 36px;
			line-height: 36px;
			background: #DCFF03;
			border-radius: 5px;
			font-size: 16px;
			text-align: center;
			color: #010085;
			cursor: pointer;
		}
	}

	.see-image-mask {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
		position: fixed;
		left: 0;
		top: 0;
		z-index: 200;

		img {
			max-width: 80%;
			max-height: 80%;
			border: 5px solid #fff;
			border-radius: 5px;
		}
	}
</style>
