<template>
	<div class="flex-wrap 	">
		<div class="item marb20 cure-pointer marr24" v-for="(item, i) in lists" :key="i" @click="toDetail(item.id)">
			<div style="overflow: hidden;">
				
					<el-image class="img_01 display-block" :src="item.image" fit="cover"></el-image>
			</div>
			<div class="sin-name-area align-items-center">
				<span class="fz20 co-333333 font-blod  one-point ">{{item.title}}</span>
				<img  v-if="item.is_advanced==1" class="sign-01 display-block marl10" src="@/assets/img/sign-01.png" alt="" />
			</div>
			<div class="desc justify-content-center-between fz16 co-999999 mart20 paddb16">
				<span>{{item.favorite}}推荐</span>
				<span>{{item.comment}}报名</span>
			</div>
			<!-- <div class="paddlr16 marb10" v-if="item.avatar || item.nickname">
				<div class="align-items-center ">
					
					<img class="icon_02 "  :src="item.avatar" alt="" />
					<text class="fz16 co-222222 font-blod marl10">{{item.nickname || ''}}</text>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			// 数据列表
			list: {
				type: Array,
				default () {
					return []
				}
			}
		},
		data() {
			return {
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				lists: [], // 列表
			}
		},
		watch: {
			list(newval) {
				this.lists = newval
		
			},
		},
		created() {
			this.lists = this.list
		
		},
		methods: {
			// 跳转详情
		
			toDetail(id) {
				this.$router.push({
					path:'/SincerityDetail',
					query: { type: 1,
							id:id
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.item {
		width: 280px;
		background: #FFFFFF;
		border-radius: 8px;
		
		.img_01 {
			width: 285px;
			height: 220px;
			border-radius: 8px 8px 0px 0px;
		}
		.icon_02 {
			width: 20px;
			height: 20px;
			border-radius: 50%;
		}
		.sin-name-area {
			padding-top: 18px;
			padding-left: 15px;
			padding-right: 15px;
			padding-bottom: 16px;
			
			.sign-01 {
				width: 20px;
				height: 20px;
			}
			
		}
		
		.desc {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
</style>
