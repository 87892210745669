<template>
	<div class="display-flex flex-wrap">
		<div class="item boderra8 marb20" v-for="(item, i) in lists" :key="i" @click="toDetail(item.id)">
			<div>
				<!-- <img class="app_06 display-block w100" :src="itme" alt="" /> -->
			<el-image class="app_06 display-block w100" :src="item.avatar" fit="cover"></el-image>
			</div>
			<div class="fz20 co-333333 paddt20 item-name two-point">{{item.name}}</div>
			<div class="fz20 co-333333 paddt12 item-name">{{item.school}}</div>
			<div class="flex-wrap mart22 paddb15 item-name" v-if="item.profession">
				<div class="label-item center co-32B16C fz16 l16 marr4" v-for=" (vo,idx) in item.profession.slice(0, 3)" :key='idx'  >{{vo}}</div>
			</div>
			<!-- <div class="paddlr16 marb10" v-if="item.avatar || item.nickname">
				<div class="align-items-center ">
					
					<img class="icon_02 "  :src="item.avatar" alt="" />
					<text class="fz16 co-222222 font-blod marl10">{{item.nickname || ''}}</text>
				</div>
			</div> -->
		</div>

	</div>

</template>

<script>
	import {

		getCurrentInstance,

	} from 'vue';
	export default {
		props: {
			list: {
				type: Array,
				default () {
					return []
				}
			}
		},
		data() {
			return {
				state: 0, // 列表加载状态
				page: 0, // 分页
				lists: []
			}
		},
		watch: {
			list(newval) {
				this.lists = newval
				console.log(newval)
			},
		},
		created() {
			this.lists = this.list
		
		},
		methods: {
			// 跳转详情
			toDetail(id) {
				this.$router.push({
					path: '/TeacherDetail',
					query: {
						type: 1,
						id: id
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.item {
		width: 285px;
		border: 1px solid #F7F7F7;
		height: 100%;
		margin-right: 15px;
		box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
		.item-name {
			padding-left: 15px;
			padding-right: 16px;
		}
	
		.label-item {
			width: 78px;
			height: 28px;
			background: rgba(50, 177, 108, 0.12);
			border-radius: 4px;
			margin-top: 5px;
		}
	
		.label-item-01 {
			width: 78px;
			height: 28px;
			background: rgba(241, 145, 73, 0.12);
			border-radius: 4px;
		}
		.icon_02 {
			width: 20px;
			height: 20px;
			border-radius: 50%;
		}
		.app_06 {
			height: 220px;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
	}
	.item:nth-child(4n) {
		margin-right: 0;
	}
</style>
